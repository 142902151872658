<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      back
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <v-card
        v-if="lineaActual !== null"
        key="conLineaActual"
        flat
      >
        <v-card-title>
          <h3
            class="title text-truncate"
          >
            {{ lineaActual.codigo }} - {{ lineaActual.descripcion }}
          </h3>
        </v-card-title>
        <v-card-title
          class="justify-center"
        >
          <v-avatar
            v-ripple
            color="red"
            size="64"
          >
            <span class="white--text display-2">{{ lineaActual.unidades_falta_servir }}</span>
          </v-avatar>
        </v-card-title>
      </v-card>
      <v-list
        v-show="formattedLineas.length > 0"
      >
        <v-list-item
          v-for="linea in formattedLineas"
          :key="linea.idlpedidocli"
        >
          <v-list-item-avatar>
            <div
              class="text-xs-center"
            >
              <v-avatar
                :color="linea.avatarColor"
              >
                <span class="white--text">{{ linea.avatar }}</span>
              </v-avatar>
            </div>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-html="linea.title"
            />
            <v-list-item-subtitle
              v-html="linea.subtitle"
            />
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              @click="clickLpedidocli(linea)"
            >
              <v-icon>{{ $vuetify.icons.values.almacen }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <b10-bottom-navigation
        :buttons="bottomNavBarButtons"
        @click-bottom-nav-bar-button="clickBottomNavBarButton"
      />
      <v-footer
        fixed
        class="justify-center"
        color="white"
      >
        {{ barcodeKeyDown }}
      </v-footer>
    </b10-page-content>
    <v-dialog
      v-model="showingDialogs.tlineaProduccion"
      width="500"
    >
      <v-card>
        <v-card-title>
          Seleccionar
        </v-card-title>
        <v-card-text>
          <b10-autocomplete
            v-model="idtlineaProduccion"
            clearable
            :items="tlineasProduccion"
            item-text="descripcion"
            item-value="idtlinea_produccion"
            label="Línea de producción"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click.stop="showingDialogs.tlineaProduccion = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click.stop="clickTlineaProduccion"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-sheet
      v-model="showingDialogs.buscarArticulo"
    >
      <v-sheet
        height="100%"
        class="pa-3"
      >
        <!-- .native porque se vuelve a disparar con el intro final -->
        <v-text-field
          v-model="barcodeBuscarArticulo"
          label="Código de artículo"
          autofocus
          @change.native="changeBuscarArticulo"
        />
      </v-sheet>
    </v-bottom-sheet>
    <b10-qr-reader
      :showing.sync="showingDialogs.scanQR"
      title="Leer código QR"
      @decode="decodeQR"
    />
  </b10-base>
</template>

<script>
import { get } from 'vuex-pathify'
import { basePageMixin } from '@/mixins/basePageMixin'
import { ItemNotFoundError } from '@/utils/consts'
import _ from '@/utils/lodash'
import Data from './PedidoClienteServirData'
import { currentDate } from '@/utils/date'
import colors from 'vuetify/lib/util/colors'

export default {
  mixins: [basePageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      toolbarOptions: {
        tlineaProduccion: {
          title: 'Línea de producción',
          visible: true,
          icon: 'filter'
        }
      },
      lineas: [],
      idtlineaProduccion: null,
      tlineasProduccion: [],
      barcodeKeyDown: '',
      barcodeProcesando: false,
      soundInitialized: false,
      soundError: null,
      soundSuccess: null,
      pedidoCliente: {},
      albaranCliente: null,
      lineaActual: null,
      showingDialogs: {
        buscarArticulo: false,
        tlineaProduccion: false,
        scanQR: false,
      },
      barcodeBuscarArticulo: '',
    }
  },
  computed: {
    almacenIdtlineaProduccion: get('almacen/idtlineaProduccion'),
    formattedLineas () {
      const lineas = []
      for (let linea of this.lineas) {
        linea.title = `${linea.codigo} - ${linea.descripcion}`
        linea.subtitle = linea.codbarras
        if (linea.observaciones) {
          linea.subtitle += ` - ${linea.observaciones}`
        }
        if (linea.unidades_servidas > 0) {
          linea.avatar = `${linea.unidades_servidas}/${linea.unidades}`
          linea.avatarColor = colors.orange.base
        } else {
          linea.avatar = linea.unidades
          linea.avatarColor = colors.red.base
        }
        lineas.push(linea)
      }
      return lineas
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  beforeDestroy () {
    this.unlistenKeyDown()
  },
  methods: {
    async loadPage () {
      this.listenKeyDown()
      this.pedidoCliente = await Data.rowPedidoCliente(this, this.routeParams.idpedidocli)
      if (this.pedidoCliente) {
        this.soundError = new Audio(require('@/assets/sound-error.wav'))
        this.soundSuccess = new Audio(require('@/assets/sound-success.wav'))
        this.tlineasProduccion = await Data.selectTlineaProduccion(this)
        this.idtlineaProduccion = await this.almacenIdtlineaProduccion
        await this.loadLineas()
        this.title = `Servir pedido ${this.pedidoCliente.serie_numero}`
        this.loadNavigationBottomButtons()
      } else {
        throw new ItemNotFoundError('El pedido que intentas visualizar no existe o pudo haber sido eliminado')
      }
    },
    async loadNavigationBottomButtons () {
      this.initNavigationBottom()
      this.addNavigationBottomButton(
        'Editar', 'editar', 'edit'
      )
      this.addNavigationBottomButton(
        'QR', 'qr', 'qr'
      )
    },
    listenKeyDown () {
      window.addEventListener('keydown', this.keyDownBarcode)
    },
    unlistenKeyDown () {
      window.removeEventListener('keydown', this.keyDownBarcode)
    },
    async loadLineas () {
      this.lineaActual = null
      this.lineas = await Data.selectLineaPedidoCliente(
        this, this.routeParams.idpedidocli, this.idtlineaProduccion
      )
      if (this.lineas.length === 0) {
        this.$alert.showSnackbarInfo('No hay artículos pendientes de servir')
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.tlineaProduccion) {
        this.showingDialogs.tlineaProduccion = !this.showingDialogs.tlineaProduccion
      }
    },
    async initSound () {
      const soundSilencio = new Audio(require('@/assets/silence.wav'))
      try {
        await soundSilencio.play(-1)
      } catch {
        // the play() request was interrupted by a call to pause()
      }
      this.soundInitialized = true
    },
    async soundErrorPlay () {
      if (!this.soundInitialized) {
        await this.initSound()
      }
      await this.soundError.play()
    },
    async soundSuccessPlay () {
      if (!this.soundInitialized) {
        await this.initSound()
      }
      await this.soundSuccess.play()
    },
    clickBuscarArticulo () {
      this.showingDialogs.buscarArticulo = true
    },
    async changeBuscarArticulo () {
      if (this.barcodeBuscarArticulo) {
        this.showingDialogs.buscarArticulo = false
        await this.barcodeRead(this.barcodeBuscarArticulo)
        this.barcodeBuscarArticulo = ''
      }
    },
    async keyDownBarcode (value) {
      if (!this.showingDialogs.buscarArticulo) {
        if (this.barcodeProcesando) {
          await this.soundErrorPlay()
        } else {
          if (value.keyCode === 13) {
            this.barcodeProcesando = true
            try {
              await this.barcodeRead(this.barcodeKeyDown)
              this.barcodeKeyDown = ''
            } finally {
              this.barcodeProcesando = false
            }
          } else if (value.keyCode >= 32 && value.key.length === 1) {
            this.barcodeKeyDown += value.key
          }
        }
      }
    },
    async barcodeRead (value) {
      if (value) {
        this.$loading.showManual(`Sirviendo código ${value}`)
        try {
          const lineaPedido = _.find(this.lineas, linea => (linea.codbarras === value || linea.codigo === value))
          // es un código EAN / código de artículo perteneciente al pedido?
          if (lineaPedido) {
            await this.servirLineaPedido(lineaPedido)
          } else {
            // es un código QR de fabricación de un artículo perteneciente al pedido?
            let continuar = true
            const decodedQR = await Data.convertirQR(this, value)
            if (decodedQR) {
              const lineaPedidoQR = _.find(this.lineas, linea => linea.idarticulo === decodedQR.idarticulo)
              if (lineaPedidoQR) {
                await this.servirLineaPedido(
                  lineaPedidoQR, decodedQR.mezcla, decodedQR.lote, decodedQR.nserie,
                  decodedQR.ffabricacion, value
                )
                continuar = false
              }
            }
            if (continuar) {
              this.lineaActual = null
              // es un código EAN de artículo fuera del pedido?
              const articulo = await Data.rowArticuloCodBarras(this, value)
              if (articulo) {
                const resp = await this.$alert.showConfirm(
                  `El artículo <strong>${articulo.codigo} - ${articulo.descripcion}</strong> no se encuentra dentro del pedido. ¿Deseas servirlo de todos modos?`
                )
                if (resp) {
                  await this.servirUnidadesFueraDePedido(articulo.idarticulo, articulo.nserie_obligado)
                }
              } else {
                // es un código QR de fabricación fuera del pedido?
                if (decodedQR) {
                  const articuloQR = await Data.rowArticulo(this, decodedQR.idarticulo)
                  const resp = await this.$alert.showConfirm(
                    `El artículo <strong>${articuloQR.codigo} - ${articuloQR.descripcion}</strong> no se encuentra dentro del pedido. ¿Deseas servirlo de todos modos?`
                  )
                  if (resp) {
                    await this.servirUnidadesFueraDePedido(
                      decodedQR.idarticulo, articuloQR.nserie_obligado, decodedQR.nserie, decodedQR.mezcla,
                      decodedQR.lote, decodedQR.ffabricacion, value
                    )
                  }
                } else {
                  await this.soundErrorPlay()
                  this.$alert.showSnackbarError('Código de artículo no encontrado')
                }
              }
            }
          }
        } finally {
          this.$loading.hide()
        }
      }
    },
    async generarAlbaranCliente () {
      if (!this.albaranCliente) {
        this.albaranCliente = await Data.idalbarancliAServir(
          this, this.routeParams.idpedidocli
        )
        if (!this.albaranCliente) {
          this.albaranCliente   = await Data.crearAlbaranDesdePedido(
            this, this.routeParams.idpedidocli, currentDate()
          )
        }
      }
    },
    async servirLineaPedido (
      lineaPedido, mezcla = null, lote = null, nserie = null, ffabricacion = null, codfabricacion = null
    ) {
      this.lineaActual = lineaPedido
      await this.servirUnidadesLineaActual(nserie, mezcla, lote, ffabricacion, codfabricacion)
      // falta algo por servir?
      if (this.lineaActual.unidades_falta_servir === 0) {
        await this.soundSuccessPlay()
        this.$alert.showSnackbarSuccess(
          `${this.lineaActual.codigo} - ${this.lineaActual.descripcion} completado`
        )
        const indexLinea = _.findIndex(
          this.lineas, { idarticulo: this.lineaActual.idarticulo }
        )
        if (indexLinea > -1) {
          this.lineas.splice(indexLinea, 1)
        }
        this.lineaActual = null
      }
    },
    async pedirNserieObligatorio () {
      this.unlistenKeyDown()
      try {
        const resp = await this.$alert.showPrompt('Nº de serie obligatorio', 'Nº de serie')
        return resp
      } finally {
        this.listenKeyDown()
      }
    },
    async servirUnidadesLineaActual (nserie, mezcla, lote, ffabricacion, codfabricacion) {
      let servir = true
      if (this.lineaActual.nserie_obligado === 1 && !nserie) {
        const nserieObligatorio = await this.pedirNserieObligatorio()
        if (nserieObligatorio) {
          nserie = nserieObligatorio
        } else {
          servir = false
        }
      }
      if (servir) {
        await this.generarAlbaranCliente()
        this.lineaActual.unidades_servidas += 1
        this.lineaActual.unidades_falta_servir -= 1
        await Data.anadirArticuloAlbaran(
          this, this.lineaActual.idlpedidocli, this.albaranCliente.idalbarancli,
          nserie, mezcla, lote, ffabricacion, codfabricacion
        )
      }
    },
    async servirUnidadesFueraDePedido (
      idarticulo, nserieObligado,
      nserie = null, mezcla = null, lote = null, ffabricacion = null, codfabricacion = null
    ) {
      let servir = true
      if (nserieObligado === 1 && !nserie) {
        const nserieObligatorio = await this.pedirNserieObligatorio()
        if (nserieObligatorio) {
          nserie = nserieObligatorio
        } else {
          servir = false
        }
      }
      if (servir) {
        await this.generarAlbaranCliente()
        await Data.anadirArticuloAlbaranSinPedido(
          this, this.albaranCliente.idalbarancli, idarticulo,
          nserie, mezcla, lote, ffabricacion, codfabricacion
        )
      }
    },
    async clickTlineaProduccion () {
      if (this.idtlineaProduccion) {
        await this.$store.set('almacen/idtlineaProduccion', this.idtlineaProduccion)
      } else {
        await this.$store.set('almacen/idtlineaProduccion', null)
      }
      this.showingDialogs.tlineaProduccion = false
      this.loadLineas()
    },
    clickLpedidocli (linea) {
      this.$appRouter.push({
        name: 'pedidos__pedido-cliente-servir-linea',
        params: {
          idpedidocli: this.routeParams.idpedidocli,
          idlpedidocli: linea.idlpedidocli
        }
      })
    },
    clickQR () {
      this.showingDialogs.scanQR = !this.showingDialogs.scanQR
    },
    async decodeQR (value) {
      if (value) {
        await this.barcodeRead(value)
        this.barcodeBuscarArticulo = ''
      }
    },
    clickBottomNavBarButton (data) {
      switch (data.button.name) {
      case 'editar':
        this.clickBuscarArticulo()
        break
      case 'qr':
        this.clickQR()
        break
      default:
        console.error(`La opción ${data.button.name} no está contemplada`)
      }
    },
  }
}
</script>
